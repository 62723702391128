@font-face {
  font-family: 'Zpix';
  src: local('Zpix'), url(./fonts/Zpix.ttf) format('truetype');
}

html, body, #root {
  height: 100%;
}

body {
  font-family: Zpix;
}

img {
  image-rendering: pixelated;
}

.nes-radio:checked:hover + span::before, .nes-radio:checked:focus + span::before {
  animation: none;
}

.nes-radio:checked:focus + span::before {
  width: 2px;
  height: 2px;
  color: #212529;
  box-shadow: 2px 2px,4px 2px,2px 4px,4px 4px,6px 4px,8px 4px,2px 6px,4px 6px,6px 6px,8px 6px,10px 6px,2px 8px,4px 8px,6px 8px,8px 8px,10px 8px,12px 8px,2px 10px,4px 10px,6px 10px,8px 10px,10px 10px,2px 12px,4px 12px,6px 12px,8px 12px,2px 14px,4px 14px;
}
